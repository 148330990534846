import React from "react";
import * as BABYLON from "babylonjs";
import SceneComponent from "./SceneComponent";
import "./App.css";
import "babylonjs-loaders";
import cannon from "cannon";
import { createBuggy } from "./prefabs/vehicles/buggy";
import { createGround } from "./prefabs/ground";

const onSceneReady = (scene: BABYLON.Scene) => {
  const engine = scene.getEngine();
  const canvas = engine.getRenderingCanvas();

  scene.clearColor = new BABYLON.Color4(0, 0, 0, 1);

  const camera = new BABYLON.ArcRotateCamera(
    "camera1",
    -Math.PI / 3,
    (5 * Math.PI) / 12,
    20,
    new BABYLON.Vector3(0, 5, 0),
    scene
  );
  camera.attachControl(canvas, true);

  new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0), scene);

  var gravityVector = new BABYLON.Vector3(0, -9.81, 0);
  var physicsPlugin = new BABYLON.CannonJSPlugin(undefined, undefined, cannon);
  const initialized = scene.enablePhysics(gravityVector, physicsPlugin);
  console.log({ initialized });

  createGround(scene);

  // const box = BABYLON.MeshBuilder.CreateBox("box", { size: 100 }, scene);
  // box.scaling.y = 0.1;
  // box.physicsImpostor = new BABYLON.PhysicsImpostor(
  //   box,
  //   BABYLON.PhysicsImpostor.BoxImpostor,
  //   { mass: 0 }
  // );

  createBuggy().then((buggy) => {
    buggy.position.y = 50;
    buggy.position.x = 0;
    buggy.position.z = 0;
  });

  // BABYLON.SceneLoader.ImportMeshAsync(
  //   "",
  //   "/assets/vehicles/",
  //   "SM_Veh_Apc_01.gltf"
  // ).then((result) => {
  //   result.meshes[0].position.x = -10;
  //   result.meshes[0].position.y = 5;
  // });

  // BABYLON.SceneLoader.ImportMeshAsync(
  //   "",
  //   "/assets/vehicles/",
  //   "SM_Veh_Apc_02.gltf"
  // ).then((result) => {
  //   result.meshes[0].position.x = -20;
  //   result.meshes[0].position.y = 5;
  // });

  // BABYLON.SceneLoader.ImportMeshAsync(
  //   "",
  //   "/assets/vehicles/",
  //   "SM_Veh_DropShip_01.gltf"
  // ).then((result) => {
  //   result.meshes[0].position.x = 20;
  //   result.meshes[0].position.y = 10;
  // });

  engine.runRenderLoop(function () {
    camera.alpha += 0.003;
  });
};

const onRender = (scene: BABYLON.Scene) => {};

const App = () => (
  <SceneComponent
    antialias
    engineOptions={{}}
    sceneOptions={{}}
    onSceneReady={onSceneReady}
    onRender={onRender}
    id="game"
  />
);

export default App;
