import * as BABYLON from 'babylonjs';

const visibleColliders = false;

export async function createBuggy(): Promise<BABYLON.AbstractMesh> {
  const result = await BABYLON.SceneLoader.ImportMeshAsync(
    "",
    "/assets/vehicles/",
    "SM_Veh_Buggy_01.gltf"
  )
  console.log({ result });
  const root = result.meshes[0];

  root.getChildMeshes()


  const lf = result.meshes.find((mesh) => mesh.id === 'SM_Veh_Buggy_01_Wheel_lf')!;
  const lr = result.meshes.find((mesh) => mesh.id === 'SM_Veh_Buggy_01_Wheel_lr')!;
  const rf = result.meshes.find((mesh) => mesh.id === 'SM_Veh_Buggy_01_Wheel_rf')!;
  const rr = result.meshes.find((mesh) => mesh.id === 'SM_Veh_Buggy_01_Wheel_rr')!;
  const car = result.meshes.find((mesh) => mesh.id === 'SM_Veh_Buggy_01')!;


  makeBoxCollider(lf, root);
  makeBoxCollider(lr, root);
  makeBoxCollider(rf, root);
  makeBoxCollider(rr, root);
  const carCollider = makeBoxCollider(car, root);
  carCollider.position.y += 1.7;

  // const boundingBox = car.getBoundingInfo().boundingBox;
  // const collider = BABYLON.CreateBox("buggyCollider", {
  //   height: boundingBox.maximum.y - boundingBox.minimum.y,
  //   width: boundingBox.maximum.x - boundingBox.minimum.x,
  //   depth: boundingBox.maximum.z - boundingBox.minimum.z,
  // });
  // collider.position = car.position;
  // collider.isVisible = false;
  // root.addChild(collider);

  root.physicsImpostor = new BABYLON.PhysicsImpostor(root, BABYLON.PhysicsImpostor.NoImpostor, {
    mass: 1000,
    friction: 0.2,
  });

  // root.physicsImpostor.onCollide = (e) => {
  //   console.log({ e });
  // }

  // inspectChildren(root);

  return root;
}

function makeBoxCollider(mesh: BABYLON.AbstractMesh, root: BABYLON.AbstractMesh): BABYLON.Mesh {

  const boundingBox = mesh.getBoundingInfo().boundingBox;
  const collider = BABYLON.CreateBox(`${mesh.id}_Collider`, {
    height: boundingBox.maximum.y - boundingBox.minimum.y,
    width: boundingBox.maximum.x - boundingBox.minimum.x,
    depth: boundingBox.maximum.z - boundingBox.minimum.z,
  });
  // collider.position = wheelMesh.position;

  collider.setAbsolutePosition(mesh.absolutePosition);
  if (visibleColliders) {
    collider.showBoundingBox = true;
    collider.visibility = 0.5;
  } else {
    collider.isVisible = false;
  }
  root.addChild(collider);

  collider.physicsImpostor = new BABYLON.PhysicsImpostor(collider, BABYLON.PhysicsImpostor.BoxImpostor, {
    mass: 0,
  });

  // wheelMesh.renderOutline = true;
  // wheelMesh.outlineColor = new BABYLON.Color3(1, 1, 1);
  // wheelMesh.outlineWidth = 0.05;

  // const joint = new BABYLON.PhysicsJoint(BABYLON.PhysicsJoint.DistanceJoint, {});
  // root.physicsImpostor?.addJoint(wheelMesh.physicsImpostor, joint);

  // inspectChildren(root);

  return collider;

}


